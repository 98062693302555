import {Component, OnInit} from '@angular/core';
import {ProductsService} from '../../services/products.service';
import {ProductModel} from '../../classes/product.model';
import {NavigationEnd, Router} from '@angular/router';
import {MEDIUM_DIALOG, trackByFn} from '@ft/core';
import {debounce, filter, tap} from 'rxjs/operators';
import {merge, timer} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ProductsManageDialog} from '../../dialogs/products-manage/products-manage.dialog';

@Component({
    selector: 'ftm-products-main',
    templateUrl: './main.component.html',
    styleUrls: [
        './main.component.scss'
    ]
})
export class ProductsMainComponent implements OnInit {
    public products: ProductModel[] = [];
    public trackByFn = trackByFn('id');

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _service: ProductsService
    ) {
    }

    public ngOnInit() {
        this._routerEvents
            .subscribe(() => {
                const currentProduct = this._service.currentProduct$.getValue() || this.products[0];
                const defaultLinkUrl = this._router.createUrlTree(
                    [currentProduct.path, currentProduct.defaultLink?.path],
                    {queryParams: currentProduct.defaultLink?.params}
                ).toString();

                if (this._router.url === '/products' || this._router.url === currentProduct.path) {
                    return this._router.navigateByUrl(defaultLinkUrl);
                }
            });
    }

    public openManageDialog() {
        const dialogRef = this._dialog.open(ProductsManageDialog, MEDIUM_DIALOG);

        dialogRef.afterClosed().subscribe(
            data => {

            }
        );
    }

    private get _routerEvents() {
        const routerEvent = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        return merge(routerEvent, this._service.products$, this._service.currentProduct$)
            .pipe(
                debounce(() => timer(50)),
                tap(() => this.products = this._service.products$.getValue())
            );
    }

}
