import {Component, OnInit} from '@angular/core';

import {GeneralPurposeService} from '@ft/core';
import {SwUpdate} from '@angular/service-worker';

@Component({
    selector: 'ftm-root-app',
    template: `
        <router-outlet></router-outlet>
    `,
    styles: []
})
export class AppComponent implements OnInit {
    constructor(
        private _swUpdate: SwUpdate,
        private _generalPurpose: GeneralPurposeService
    ) {
    }

    public ngOnInit(): void {
        if (this._swUpdate.isEnabled) {
            this._swUpdate.available.subscribe(() => {
                this._generalPurpose.showAppCodeUpdateToast();
            });
        }
    }
}
