// main component
import {Component, OnInit} from '@angular/core';
import {AppConfigsService, GeneralPurposeService, ToolbarConfig} from '@ft/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {chain, get} from 'lodash';
import {map, mergeMap} from 'rxjs/operators';
import {ProductsService} from '../../products/services/products.service';
import {saveAs} from '@d0whc3r/file-saver';
import {ServerLicenseErrorInterface, ServerLicenseInterface} from '../../products/classes/server-license.interface';

@Component({
    selector: 'ftm-main',
    host: {class: 'ft-main-component'},
    template: `
        <ft-app-toolbar [config]="toolbarConfig"></ft-app-toolbar>
        <router-outlet></router-outlet>
        <div *ngIf="demoUse" class="demo-use-overlay" translate="shared.demo_use_license"></div>
    `
})
export class FtmMainComponent implements OnInit {
    public toolbarConfig: ToolbarConfig;
    public license: ServerLicenseInterface | ServerLicenseErrorInterface;
    public demoUse: boolean;

    constructor(
        private _snackBar: MatSnackBar,
        private _translate: TranslateService,
        _appConfigs: AppConfigsService,
        private _generalPurpose: GeneralPurposeService,
        private _productsService: ProductsService
    ) {
        this.toolbarConfig = {
            logo: '/assets/logo.png',
            logoutIcon: 'mdi-logout',
            links: [
                {
                    icon: 'mdi-apps',
                    label: 'products.title',
                    path: '/products'
                },
                {
                    icon: 'mdi-cogs',
                    label: 'shared_services.title',
                    path: '/shared-services'
                },
                {
                    icon: 'mdi-file-find',
                    label: 'file_manager.title',
                    path: '/file-manager/main'
                },
                {
                    icon: 'mdi-database-eye',
                    label: 'db.title',
                    path: '/db-managers/nosql-client'
                },
                {
                    icon: 'mdi-console',
                    label: 'ssh.title',
                    path: '/ssh',
                    target: '_blank'
                },
                {
                    external: true,
                    icon: 'mdi-printer-settings',
                    label: 'shared.cups_title',
                    path: '/cups/'
                }
            ],
            actions: [
                {
                    class: '',
                    icon: 'mdi-download',
                    tooltip: 'products.certificate.download_keystore',
                    method: () => this.downloadKeystore()
                },
                {
                    class: '',
                    icon: 'mdi-progress-clock',
                    tooltip: 'shared.restart.planed_restart',
                    method: () => this.autoRestart()
                },
                {
                    class: '',
                    href: '/printers',
                    icon: 'mdi-printer-pos-cog',
                    tooltip: 'printing.tooltip_label'
                },
                {
                    class: 'mat-primary',
                    icon: 'mdi-information-variant-circle-outline',
                    tooltip: _appConfigs.machineUuid,
                    method: () => this.licenseManager()
                },

                {
                    class: 'ft-success-color button-separated',
                    icon: 'mdi-restart-alert',
                    tooltip: 'shared.restart.reboot_device',
                    method: () => this.handleDevice('reboot')
                },
                {
                    class: 'mat-warn',
                    icon: 'mdi-power-plug-off',
                    tooltip: 'shared.restart.shutdown_device',
                    method: () => this.handleDevice('shutdown')
                }
            ],
            phoneNumbers: []
        };
    }

    ngOnInit(): void {
        this.license = this._productsService.license$.getValue();
        if ('error' in this.license && this.license.error) {
            const error = this.license;
            this.license = null;
            this._productsService.license$.next(null);
            this.licenseManager(true, error);
        } else {
            this.demoUse = this._productsService.demoUse;
        }
    }

    public downloadKeystore() {
        this._generalPurpose.downloadNative('/keystore/')
            .subscribe(response => {
                const contentDispositionHeader = response.headers.get('content-disposition');
                const fileName = chain(contentDispositionHeader)
                    .split(';').last()
                    .split('=').last()
                    .value();

                saveAs(response.body, fileName);
            });
    }

    public autoRestart() {
        this._generalPurpose.getByEvent('various.restart_config')
            .pipe(
                mergeMap(data => this._generalPurpose.openPromptDialog(
                    'shared.restart.title', 'shared.restart.cron_config',
                    null, null, data.CRON
                )),
                map(config => new Object({CRON: config})),
                mergeMap(data => this._generalPurpose.getByEvent('various.set_restart', data))
            )
            .subscribe(() => {
                const copyMsg = this._translate.instant('shared.restart.config_success');
                this._snackBar.open(copyMsg, null, {duration: 1000});
            });
    }

    public handleDevice(cmd: string) {
        this._generalPurpose.openConfirmDialog(`shared.restart.${cmd}_confirm`)
            .pipe(
                mergeMap(() => this._generalPurpose.getByEvent(`various.${cmd}`))
            ).subscribe();
    }

    public licenseManager(lock: boolean = false, error: ServerLicenseErrorInterface = null) {
        this._productsService.showLicenseManager(lock, this.license || error);
    }
}
