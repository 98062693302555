import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SshRoutingModule} from './ssh-routing.module';
import {SshComponent} from './components/ssh/ssh.component';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ConnectDialog} from './dialogs/connect/connect.dialog';

@NgModule({
    declarations: [
        SshComponent, ConnectDialog
    ],
    imports: [
        CommonModule,
        SshRoutingModule,
        MaterialModule,
        FormsModule,
        FtCoreModule,
        TranslateModule.forChild()
    ]
})
export class SshModule {
}
