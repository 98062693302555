export const lang = {
    title: 'Gestionnaire des fichiers',
    file_manager: 'File Manager',
    name: 'Nom',
    permission: 'Permission',
    size: 'Taille',
    created: 'Créé le',
    updated: 'Modifié le',
    save: 'Enregistrer',
    cancel: 'Annuler',
    download: 'Télecharger',
    delete: 'Supprimer',
    add_file_title: 'Nouveau folder/file',
    add_file_placeholder: 'Entrer un nouveau nom:',
    upload_file_error: 'Erreur de téléchargement',
    rename: 'Renommer',
    rename_file_placeholder: 'Entrer un nouveau nom pour votre fichier:',
    move: 'Deplacer',
    move_file_placeholder: 'Deplacer vers...',
    move_file_force: 'Fichier déja exist, Voulez vous écraser l\'ancien?',
    saved_success: 'Enregistré avec succès',
    saved_error: 'Fixer les erreurs!',
    no_element: 'Aucun élément trouvé',
    path_copied: 'Chemin copié',
    error_reading_file: 'Erreur lors de la lecture du fichier',

    error_access: 'Accès refusé',
    permission_denied: 'Permission refusée',
    path_not_found: 'Le chemin spécifié est introuvable!',
    already_exist: 'Fichier ou dossier existe déjà!',
    not_exist: 'Fichier ou dossier n\'existe pas',
    no_file: 'Fichier n\'existe pas',
    error_name_exist: 'Impossible de renommer ce fichier, ce nom existe déjà',
    error_move: 'Impossible de déplacer à ce chemin',
    dest_exist: 'Le fichier existe déjà dans ce chemin',
    error_upload: 'Impossible de télécharger dans ce chemin',
};
