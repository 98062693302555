<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-lan-connect"></mat-icon>
    </div>
    <h2 mat-dialog-title>{{'ssh.title' | translate}}</h2>
</mat-toolbar>

<form fxLayout="column" #form="ngForm" (ftAsyncSubmit)="submit()">

    <div mat-dialog-content>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'ssh.host' | translate" [(ngModel)]="data.host" name="host" required />
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput ftNumber [fix]="0" [min]="1" [max]="65535" [placeholder]="'ssh.port' | translate"
                       [(ngModel)]="data.port" name="port" required>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'ssh.username' | translate" [(ngModel)]="data.username" name="username"
                       required>
            </mat-form-field>

            <mat-form-field fxFlex>
                <input matInput [placeholder]="'ssh.password' | translate" [(ngModel)]="data.password" name="password"
                       required
                       [type]="hide ? 'password' : 'text'" [ftAutoFocus]="true">
                <mat-icon class="ft-module-icon" matSuffix fontSet="mdi" [fontIcon]="hide ? 'mdi-eye-off' : 'mdi-eye'"
                          (click)="hide = !hide"></mat-icon>
            </mat-form-field>
        </div>

    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid" tabindex="2">
            <span translate="ssh.login"></span>
        </button>
        <button mat-raised-button color="warn" type="button" mat-dialog-close tabindex="-1">
            <span translate="ssh.cancel"></span>
        </button>
    </div>
</form>