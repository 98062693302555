<iframe src="/phpmyadmin/" fxFlex="1 0 100%" #iframe
        [ngClass]="{'fullscreen-frame': fullPage,'mat-elevation-z2': fullPage}"
></iframe>

<div class="buttons-container" [ngClass]="{'fullscreen-frame': fullPage}"
     fxLayout="column" fxLayoutGap="6px">
    <button mat-mini-fab color="primary" fxFlex="0 0 100%" (click)="toggleFullPage()"
            ngfSelect [matTooltip]="'db.fullscreen' | translate" matTooltipPosition="right">
        <mat-icon fontSet="mdi" [fontIcon]="fullPage ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"></mat-icon>
    </button>

    <button mat-mini-fab color="primary" fxFlex="0 0 100%" (click)="reloadPage()"
            ngfSelect [matTooltip]="'db.reload' | translate" matTooltipPosition="right">
        <mat-icon fontSet="mdi" fontIcon="mdi-reload"></mat-icon>
    </button>
</div>

<div *ngIf="fullPage" class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
