import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {get} from 'lodash';
import {Subscription} from 'rxjs';
import {SharedServiceModel} from '../../classes/service.model';
import {FileManagerService} from '../../../file-manager/services/file-manager.service';


@Component({
    selector: 'ftm-shared-service-log',
    templateUrl: './service-log.dialog.html',
    styleUrls: [
        './service-log.dialog.scss'
    ]
})
export class ServiceLogDialog implements OnInit {
    public service: SharedServiceModel;
    public content: string;
    public loader: Subscription;

    constructor(
        private _fmService: FileManagerService,
        private _dialogRef: MatDialogRef<ServiceLogDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.service = get(data, 'service');
    }

    ngOnInit() {
        const log = {
            value: `sudo /usr/bin/env /usr/local/bin/docker-compose -p ${this.service.project} -f /opt/ft/docker-services/${this.service.serviceName}/docker-compose.yml logs`,
            command: true
        };

        this.loader = this._fmService.readFile(log)
            .subscribe((data: string) => this.content = data);
    }
}
