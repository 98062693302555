<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="printing.profile.dialog_label"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #profileForm="ngForm" fxLayout="column" (ftAsyncSubmit)="onSubmit()" [subscription]="loader">
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div [ngBusy]="loader"></div>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput required [placeholder]="'printing.profile.label' | translate" name="label"
                       [(ngModel)]="profile.label">
            </mat-form-field>
            <mat-form-field fxFlex>
                <ft-select required [placeholder]="'printing.profile.printer_name' | translate" [config]="printerNameConfig"
                           [(ngModel)]="profile.printer_name" name="printer_name">
                </ft-select>
            </mat-form-field>
        </div>


        <div fxLayout="row">
            <mat-form-field fxFlex>
                <ft-select required [placeholder]="'printing.profile.page_size' | translate" [config]="pageSizeConfig"
                           [(ngModel)]="profile.page_size" name="page_size">
                </ft-select>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'printing.profile.extra_args' | translate" name="extra_args"
                       [(ngModel)]="profile.extra_args">
            </mat-form-field>
        </div>

    </div>
    <div mat-dialog-actions>
        <span fxFlex></span>
        <button mat-raised-button color="primary" type="submit"
                [disabled]="profileForm.invalid">
            <span translate="common.save"></span>
        </button>
        <button mat-raised-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>
