import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {chain} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductsService} from '../../services/products.service';
import {ProductModel} from '../../classes/product.model';

@Component({
    selector: 'ftm-product-form',
    templateUrl: './product-form.dialog.html',
    styleUrls: ['./product-form.dialog.scss']
})
export class ProductFormDialog implements OnInit {
    public loader: Subscription;
    public product: ProductModel;

    constructor(
        private _service: ProductsService,
        private _dialogRef: MatDialogRef<ProductFormDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.product = chain(data).get('product').cloneDeep().value() as ProductModel;
    }

    public ngOnInit(): void {
    }


    public submit() {

    }
}
