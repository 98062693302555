import {get, isNil} from 'lodash';
import {ProductModel} from './product.model';

export class ExecutionMessage {
    public label: string;
    public step: string;
    public total: string;
    public error: string;
    public output: string;
    public progress: number;

    public product: ProductModel;
    public instance: object | boolean;

    constructor(data) {
        this.step = get(data, 'step', '-');
        this.label = get(data, 'label', '-');
        this.total = get(data, 'total', '-');
        this.error = get(data, 'error', null);
        this.output = get(data, 'output', null);
        this.progress = get(data, 'progress', -1);
        this.instance = get(data, 'instance', false);

        const productData = get(data, 'product', null);
        this.product = productData ? new ProductModel(productData) : null;
    }

    get hasError() {
        return !!this.error;
    }

    get hasOutput() {
        return !!this.output;
    }

    get hasInstance() {
        return this.instance;
    }
}
