import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {MainComponent} from './main/main.component';
import {NosqlClientComponent} from './components/nosql-client/nosql-client.component';
import {PgadminComponent} from './components/pgadmin/pgadmin.component';
import {PhpMyAdminComponent} from './components/phpmyadmin/phpMyAdmin.component';


@NgModule({
    declarations: [
        MainComponent,
        NosqlClientComponent,
        PgadminComponent,
        PhpMyAdminComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        TranslateModule.forChild(),
        FtCoreModule,
        MaterialModule
    ]
})
export class DatabasesManagersModule {
}
