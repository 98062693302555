<div fxFlex fxLayout="column" class="upload-drop-container" ngfDrop
     (fileChange)="handlePackage($event)" (validDragChange)="validDrag = $event"
     [ngClass]="{'on-drag': validDrag}">
    <ft-generic-table fxFlex
                      [remote]="false"
                      [source]="source$"
                      [selectable]="false"
                      [hasPagination]="false"
                      [columns]="columns">
    </ft-generic-table>
</div>

<button mat-mini-fab color="accent" ngfSelect (fileChange)="handlePackage($event)" [matTooltip]="'products.package.upload_file' | translate" matTooltipPosition="above">
    <mat-icon fontSet="mdi" fontIcon="mdi-upload"></mat-icon>
</button>

<button mat-mini-fab color="accent" class="second-btn" (click)="attachFromPath()"
        [matTooltip]="'products.package.choose_existing_file' | translate" matTooltipPosition="above">
    <mat-icon fontSet="mdi" fontIcon="mdi-harddisk-plus"></mat-icon>
</button>
