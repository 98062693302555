import {ITerminalOptions, ITheme} from 'xterm';


const THEME: ITheme = {
    cursor: '#FF5252',
    cursorAccent: '#00202a',

    foreground: '#E7EBED',
    background: '#263238',
    selection: '#839DAA40',

    black: '#252525',
    red: '#FF5252',
    green: '#C3D82C',
    yellow: '#FFD740',
    blue: '#40C4FF',
    magenta: '#FF4081',
    cyan: '#18FFFF',
    white: '#FAFAFA',

    brightBlack: '#708284',
    brightRed: '#FF5252',
    brightGreen: '#C3D82C',
    brightYellow: '#FFD740',
    brightBlue: '#40C4FF',
    brightMagenta: '#FF4081',
    brightCyan: '#18FFFF',
    brightWhite: '#FCFCFC'
};

export const TERMINAL_OPTIONS: ITerminalOptions = {
    cursorWidth: 2,
    cursorBlink: true,
    cursorStyle: 'bar',

    rendererType: 'canvas',
    fastScrollModifier: 'alt',
    fastScrollSensitivity: 50,

    theme: THEME,
};


export class SshMessage {
    type: any;
    body: any;

    constructor(type, body = {}) {
        this.type = type;
        this.body = body;
    }

    static toMap(jsonStr) {
        return JSON.parse(jsonStr);
    }

    toJson() {
        return JSON.stringify(this);
    }
}
