<div *ngIf="!folder || folder.children && folder.children.length == 0" class="ft-empty-content" fxFlex
     [style.margin]="0">
    <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
    <span>{{'file_manager.no_element' | translate}}</span>
</div>

<ng-container *ngFor="let f of folder.children; trackBy:trackByFn">
    <ftm-file-item [file]="f" onDragStart="return false;" [attr.path]="f.path" [attr.permission]="f.permissions.w"
                   [ngClass]="{'parent-folder': f.is_parent, 'folder': f.is_folder, 'file': !f.is_folder,
                   'disabled': !f.permissions.w || f.dir == '/home'}">
    </ftm-file-item>
</ng-container>
