<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-eye"></mat-icon>
    </div>
    <h2 mat-dialog-title>{{service.id}}</h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div mat-dialog-content class="ft-busy-container ft-monaco-container">
    <div [ngBusy]="loader"></div>
    <ft-monaco-editor name="content" [(ngModel)]="content" fxFlex="grow" disabled language="plaintext"></ft-monaco-editor>
</div>


