import {BaseServiceModel} from '../../classes/base-service.model';

declare type LoadStateType =
    'active'
    | 'inactive'
    | 'activating'
    | 'deactivating'
    | 'failed'
    | 'not-found'
    | 'running'
    | 'dead';


export class SharedServiceModel extends BaseServiceModel {
    private readonly _original: any;

    constructor(data) {
        super(data.service_metadata);

        this._original = data;
    }

    get serviceName(): string {
        return this._original.service_name;
    }

    get project(): string {
        return this._original.project;
    }

    get composeServices(): string[] {
        return this._original.compose_services;
    }
}
