<mat-drawer-container class="ft-content-container" fxFlex>
    <mat-drawer mode="side" [opened]="true">
        <mat-toolbar color="primary">
            <div class="ft-module-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
            </div>
            <h2>{{ 'products.package.actions' | translate }}</h2>
            <span fxFlex></span>
        </mat-toolbar>

        <mat-nav-list role="list" class="ft-content">
            <ng-container *ngFor="let link of product?.links; trackBy:trackByFn">
                <mat-list-item role="listitem" routerLinkActive="ft-list-item-active" [@heightAnimation]>
                    <a mat-line [routerLink]="link.path" [queryParams]="link.params" [skipLocationChange]="false">
                        <mat-icon fxFlex="noshrink" fontSet="mdi" [fontIcon]="link.icon"></mat-icon>
                        <span fxFlex [title]="link.label">{{link.label}}</span>
                    </a>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </mat-drawer>

    <mat-drawer-content fxLayout="column" class="ft-content ft-padding">
        <router-outlet></router-outlet>
        <button mat-mini-fab color="primary" (click)="updateProduct()" [disabled]="!hasProduct" class="update-btn">
            <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        </button>
        <button mat-mini-fab [color]="product?.has_license ? 'primary' : 'warn'" (click)="updateLicenseProduct()"
                [disabled]="!hasProduct || !obligatoryLicensePolicy || demoUse || product?.is_manager" class="license-btn">
            <mat-icon fontSet="mdi" fontIcon="mdi-data-matrix-edit"></mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="updateCertificate()" class="ssl-btn">
            <mat-icon fontSet="mdi" fontIcon="mdi-security"></mat-icon>
        </button>
    </mat-drawer-content>

</mat-drawer-container>