import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main/main.component';
import {FormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {SharedServicesService} from './services/shared-services.service';
import {ServiceLogDialog} from './dialogs/service-log/service-log.dialog';


@NgModule({
    declarations: [
        MainComponent,
        ServiceLogDialog
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        TranslateModule.forChild(),
        FtCoreModule,
        MaterialModule
    ],
    providers: [
        SharedServicesService
    ]
})
export class SharedServicesModule {
}
