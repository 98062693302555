import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FileManagerService} from '../../services/file-manager.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {FileObject} from '../../classes/folder';
import {TOOLBAR_ANIMATION} from '@ft/core';
import {Buffer} from 'buffer';

@Component({
    selector: 'ftm-file-manager-container',
    templateUrl: './file-manager-container.component.html',
    styleUrls: ['./file-manager-container.component.scss'],
    animations: [
        TOOLBAR_ANIMATION
    ]

})
export class FileManagerContainerComponent implements OnInit, OnDestroy {
    currentFolder: FileObject;
    busy: Subscription;
    subscription: Subscription;
    public files: File[] = [];
    private readonly uploadError: string;
    pathInput = '';
    pathAsInput = false;

    constructor(private _fmService: FileManagerService,
                private _translate: TranslateService,
                private _snackBar: MatSnackBar,
                private _route: ActivatedRoute,
                private _router: Router,
                private _location: Location) {
        this.uploadError = this._translate.instant('file_manager.upload_file_error');
    }

    public trackByFn = (i, item) => item;

    ngOnInit() {
        this.subscription = this._route.queryParams
            .subscribe(params => {
                this.busy = this._fmService
                    .read(params.path ? params.path : 'root', params.folder ? params.folder === 'true' : true)
                    .subscribe({
                        next: data => {
                            this.currentFolder = data;
                            this.pathInput = data.path;
                        },
                        error: err => {
                            this.showSnackBar(err);
                            this._location.back();
                        }
                    });
            });

    }

    search(term: string): void {
        if (term.length > 2) {
            this._fmService.search(this.currentFolder.path === '/' ? this.currentFolder.path : `${this.currentFolder.path}/`, term)
                .subscribe({
                    next: suc => {
                        this.currentFolder.children = suc.results;
                    },
                    error: () => this._fmService.changeUrl()

                });
        } else if (term === '') this._fmService.changeUrl();
    }

    addFile() {
        this._fmService.create(this.currentFolder.path)
            .subscribe({
                next: () => this._fmService.changeUrl(),
                error: err => err.error ? this.showSnackBar(err) : null

            });
    }

    uploadFiles(files: File[]) {
        if (this.currentFolder.permissions.w) {
            this._fmService.upload(this.currentFolder.path, files)
                .subscribe({
                    next: () => this._fmService.changeUrl(),
                    error: err => this.showSnackBar(err)
                });
            this.files = [];
        }
    }

    showSnackBar(err) {
        this._snackBar.open(this._translate.instant(`file_manager.${err.error.description}`));
    }

    handlePath(name) {
        const queryParams = this.currentFolder.prepareQueryParams(name);
        if (queryParams) this._router.navigate(this._fmService.rootUrl, {queryParams}).then();
    }

    private goTo(path) {
        path = Buffer.from(path).toString('base64');
        return this._router.navigate(this._fmService.rootUrl, {queryParams: {path}});
    }

    goToParent() {
        return this.goTo(this.currentFolder.dir);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    pathChanged() {
        console.log(this.pathInput);
        return this.goTo(this.pathInput);
    }

    togglePathAsInput(value = false) {
        this.pathAsInput = value;
    }
}
