export const lang = {
    reload: 'Actualiser',
    fullscreen: 'Mode Full-Page',
    title: 'Gestionnaire DB',
    nosql_client: {
        title: 'NoSQL Client'
    },
    pgadmin: {
        title: 'PgAdmin'
    },
    phpmyadmin: {
        title: 'phpMyAdmin'
    }
};
