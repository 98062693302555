import {Component, Inject, OnInit} from '@angular/core';
import {ProductsService} from '../../services/products.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductModel} from '../../classes/product.model';
import {Subscription} from 'rxjs';
import {PackageLicenseInterface} from '../../classes/package-license.interface';

@Component({
    selector: 'ftm-package-license',
    templateUrl: './package-license.component.html',
    styleUrls: ['./package-license.component.scss']
})
export class PackageLicenseComponent implements OnInit {
    product: ProductModel;
    modeForm: boolean;
    licenseObject: PackageLicenseInterface;
    licenseContent = '';

    public loader: Subscription;

    constructor(private dialogRef: MatDialogRef<PackageLicenseComponent>,
                private _service: ProductsService,
                private _translate: TranslateService,
                private _snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) data: { product: ProductModel }) {
        this.product = data.product;
        this.licenseObject = data.product.license_info;
    }

    ngOnInit(): void {
        this.modeForm = !this.product.has_license;
    }

    onSubmit(): void {
        this.loader = this._service.updatePackageLicense(this.product, this.licenseContent)
            .subscribe(() => {
                this._snackBar.open(this._translate.instant('products.license.installed'), null, {duration: 1000});
                this.dialogRef.close(true);
            });

    }

    public validateField() {
        return this._service.validatePackageLicense(this.product, this.licenseContent);
    }

}
