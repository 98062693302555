import {Component, OnInit} from '@angular/core';
import {trackByFn} from '@ft/core';

@Component({
    selector: 'ftm-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    managers: { label: string, path: string }[];
    trackByFn = trackByFn('label');

    constructor() {
    }

    ngOnInit(): void {
        this.managers = [
            {
                label: 'db.nosql_client.title',
                path: '/db-managers/nosql-client'
            },
            {
                label: 'db.pgadmin.title',
                path: '/db-managers/pgadmin4'
            },
            {
                label: 'db.phpmyadmin.title',
                path: '/db-managers/phpmyadmin'
            }
        ];
    }

}
