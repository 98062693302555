<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
    </div>
    <h2 *ngIf="product.id" mat-dialog-title translate="products.handle_product" [translateParams]="product"></h2>
    <h2 *ngIf="!product.id" mat-dialog-title translate="products.setups.add_product"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #productForm="ngForm" fxLayout="column" (ftAsyncSubmit)="submit()" [subscription]="loader">
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div [ngBusy]="loader"></div>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput required [placeholder]="'products.name' | translate" name="name"
                       [(ngModel)]="product.name" autocomplete="off">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput required [placeholder]="'products.backup.main_args' | translate" name="backups_main_args"
                       [(ngModel)]="product.backups_main_args" autocomplete="off">
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions>
        <span fxFlex></span>
        <button mat-raised-button color="primary" type="submit"
                [disabled]="productForm.invalid">
            <span translate="common.save"></span>
        </button>
        <button mat-raised-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>
