import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {get} from 'lodash';
import {of, Subscription} from 'rxjs';
import {SelectConfig} from '@ft/core';

import {PAPER_SIZES} from '../../models/consts';
import {PrintersService} from '../../services/printers.service';

@Component({
    selector: 'ftm-printer-profile',
    templateUrl: './printer-profile.dialog.html',
    styleUrls: [
        './printer-profile.dialog.scss'
    ]
})
export class PrinterProfileDialog {
    public profile: any;
    public loader: Subscription;

    public pageSizeConfig: SelectConfig = {key: 'name', valueKey: 'name', observable: of(PAPER_SIZES)};
    public printerNameConfig: SelectConfig = {key: 'name', valueKey: 'name', url: this._service.printerNamesUrl};

    constructor(
        private _service: PrintersService,
        private _dialogRef: MatDialogRef<PrinterProfileDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.profile = get(data, 'profile');
    }

    public onSubmit() {
        this.loader = this._service.saveProfile(this.profile)
            .subscribe(data => this._dialogRef.close(data))
    }
}
