<div fxLayout="column">
    <div mat-dialog-content fxLayout="column" [ngClass]="{'show-adv-mode': showAdvMode}">
        <div fxFlex="nogrow" fxLayout="column">
            <h4 class="progress-title" *ngIf="message.hasInstance"
                translate="products.package.installation_succeed"></h4>
            <h4 class="progress-title" [ngClass]="{'has-error': message.hasError}"
                *ngIf="message.label">{{message.label}}</h4>
            <mat-progress-bar *ngIf="!message.hasError && !message.hasInstance" [@heightAnimation]
                              [mode]="progressMode" [value]="message.progress">
            </mat-progress-bar>
            <span class="progress-step" *ngIf="!message.hasError && !message.hasInstance" [@heightAnimation]>
                {{message.step ? message.step : 'X'}}/{{message.total}}
            </span>
        </div>
        <div fxFlex="1 0 auto" fxLayout="column">
            <div fxFlex="0 1 auto" fxLayout="row" class="adv-mode-btn">
                <span fxFlex="grow"></span>
                <button fxFlex="0 0 auto" mat-button color="primary" (click)="toggleAdvMode()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-arrow-expand"></mat-icon>
                    <span translate="products.package.show_adv"></span>
                </button>
            </div>
            <div fxFlex="0 0 340px" [ngClass]="{hidden: !showAdvMode}" class="adv-mode-container mat-elevation-z2">
                <p class="content">
                    <span class="message" *ngFor="let message of messages" fxLayout="column"
                          [ngClass]="{'has-error': message.hasError}">
                        <span fxFlex="nogrow" fxLayout="row">
                            <span fxFlex="nogrow" class="message-step">{{message.step ? message.step : 'X'}}
                                / {{message.total}}</span>
                            <span fxFlex></span>
                            <span fxFlex="nogrow" class="message-progress">[{{message.progress}} / 100%]</span>
                        </span>
                        <span fxFlex="nogrow" class="message-label">
                            {{message.label}}
                        </span>
                        <span *ngIf="message.hasOutput" class="message-output">{{message.output}}</span>
                        <span *ngIf="message.hasError" class="message-error">{{message.error}}</span>
                        <mat-divider></mat-divider>
                    </span>

                </p>
            </div>
        </div>

    </div>
    <div mat-dialog-actions *ngIf="message.hasError || message.hasInstance" [@heightAnimation]>
        <button mat-flat-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1" *ngIf="message.hasError">
            <span translate="products.package.close_dialog"></span>
        </button>
        <button mat-flat-button color="primary" type="button" [mat-dialog-close]="message.instance" tabindex="-1"
                *ngIf="message.hasInstance">
            <span translate="products.package.close_dialog"></span>
        </button>
    </div>
</div>