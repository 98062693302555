import {SSH_WORKER_CMD} from '../models/enums';

const workerString = `
    let ws = null;
    let ctx = self;
    
    let connect = function (url) {
        ws = new WebSocket(url);
        ws.onmessage = ev => postMessage({cmd: ${SSH_WORKER_CMD.MESSAGE}, data: ev.data});
    }
    
    ctx.addEventListener('message', $event => {
        switch ($event.data.cmd) {
            case ${SSH_WORKER_CMD.CONNECT}:
                connect($event.data.url);
                break;
            case ${SSH_WORKER_CMD.MESSAGE}:
                if (ws.readyState === WebSocket.OPEN) ws.send($event.data.msg);
                break;
        }
    });
`;

const workerBlob = new Blob([workerString], {type: 'application/javascript'});
export const workerUrl = URL.createObjectURL(workerBlob);
