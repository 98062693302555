<div class="ft-parent-layout ft-monaco-container mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent" fxLayout="row">
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-cogs"></mat-icon>
        </div>

        <h2>{{'shared_services.title' | translate}}</h2>
    </mat-toolbar>
    <div class="ft-content ft-monaco-container" fxFlex fxLayout="column">
        <ft-generic-table class="services-table" fxFlex
                          [remote]="false"
                          [source]="source$"
                          [selectable]="false"
                          [hasPagination]="false"
                          [columns]="columns">
        </ft-generic-table>
    </div>
</div>
