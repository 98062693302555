export const lang = {
    tooltip_label: 'Profiles des imprimantes',
    printers_label: 'Listes des imprimantes',

    add_printer_profile: 'Ajouter profile',
    remove_profile_confirm: 'Êtes-vous sûr de vouloir supprimer <strong>"{{label}}"</strong> ?',

    profile: {
        dialog_label: 'Profile d\'imprimantes',

        label: 'Label',
        printer_name: 'Nom d\'imprimante',
        page_size: 'Taille du papier',
        extra_args: 'Argument supplémentaire',
    }
};
