<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-data-matrix"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="products.license.package_dialog_title" [translateParams]="product"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #licenseForm="ngForm" fxLayout="column" (ftAsyncSubmit)="onSubmit()" [subscription]="loader">
    <div mat-dialog-content class="ft-busy-container" fxFlex="grow" fxLayout="column">
        <mat-form-field appearance="outline" class="with-error-label" *ngIf="modeForm">
            <mat-label>{{'products.license.field_title' | translate}}</mat-label>
            <textarea matInput [placeholder]="'products.license.field_title' | translate" rows="14"
                      [(ngModel)]="licenseContent" name="licenceKey" required #licenceContentField="ngModel"
                      [ftCustomValidation]="validateField()" [noWatch]="true" [watchedValue]="licenseContent">
            </textarea>
            <mat-error *ngFor="let item of licenceContentField.errors | keyvalue">
                <span *ngIf="item.key.startsWith('license_error')">{{'products.license.' + item.key | translate}}</span>
            </mat-error>
        </mat-form-field>
        <div fxLayout="column" *ngIf="!modeForm">
            <div fxLayout="row" fxFlex="noshrink">
                <div translate="products.license.max_version" class="label tag mat-elevation-z1" fxFlex="0 0 33%"></div>
                <div class="tag mat-elevation-z1" fxFlex="none">{{licenseObject.version}}</div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row">
        <button fxFlex="0 0 auto" mat-flat-button color="warn" type="button" class="has-icon" *ngIf="!modeForm"
                (click)="modeForm=true;">
            <mat-icon fontSet="mdi" fontIcon="mdi-data-matrix-edit"></mat-icon>
            <span translate="products.license.edit"></span>
        </button>
        <span fxFlex="1 0 auto"></span>
        <button fxFlex="0 0 auto" mat-flat-button color="primary" type="submit" tabindex="-1" *ngIf="!modeForm">
            <span translate="shared.cancel"></span>
        </button>
        <button fxFlex="0 0 auto" mat-flat-button color="primary" type="submit" tabindex="-1"
                *ngIf="modeForm" [disabled]="licenseForm.invalid">
            <span translate="products.license.submit"></span>
        </button>
    </div>
</form>

