<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-cog"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="products.setups.handle_setups"></h2>
    <span fxFlex></span>
    <button mat-button class="with-icon" tabindex="-1" (click)="updateProductMeta()">
        <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
        <span>{{'products.setups.add_product' | translate}}</span>
    </button>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div class="ft-content">
    <ft-generic-table fxFlex
                      [remote]="false"
                      [source]="source$"
                      [clickable]="true"
                      [hasPagination]="false"
                      [columns]="columns"
                      (lineClick)="updateProductMeta($event)">
    </ft-generic-table>
</div>
