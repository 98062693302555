import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GeneralPurposeService} from '@ft/core';

@Injectable()
export class PrintersService {
    private _baseUrl = '/api/printing';
    public printerNamesUrl = `${this._baseUrl}/printers/printer-names/`;

    constructor(private _http: HttpClient, private _generalPurpose: GeneralPurposeService) {
    }

    public getProfiles() {
        const url = `${this._baseUrl}/printers/`;
        return this._generalPurpose.getByHttp(url);
    }

    public saveProfile(profile) {
        const url = `${this._baseUrl}/printers/`;
        return this._generalPurpose.postHttp(url, profile);
    }

    public removeProfile(profile) {
        const url = `${this._baseUrl}/printers/${profile.id}/`;
        return this._http.delete(url);
    }
}
