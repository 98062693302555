export enum SSH_MESSAGE_TYPES {
    CONNECT = 0,
    OPEN = 1,
    READ = 2,
    WRITE = 3,
    RESIZE = 4,
    CLOSE = 5,
    DISCONNECT = 6
}

export enum SSH_WORKER_CMD {
    CONNECT = 0,
    MESSAGE = 1
}
