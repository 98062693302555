import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileManagerContainerComponent} from './components/file-manager-container/file-manager-container.component';
import {FolderComponent} from './components/folder/folder.component';
import {FileComponent} from './components/file/file.component';
import {FileItemComponent} from './components/file-item/file-item.component';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ngfModule} from 'angular-file';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        FileManagerContainerComponent,
        FolderComponent,
        FileComponent,
        FileItemComponent
    ],
    imports: [
        CommonModule,
        ngfModule,
        RouterModule,
        FtCoreModule,
        MaterialModule,
        FormsModule,
        TranslateModule.forChild()
    ]
})
export class FileManagerModule {
}
