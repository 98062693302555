<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-data-matrix"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="products.license.dialog_title"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1" *ngIf="!lockExit">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #licenseForm="ngForm" fxLayout="column" (ftAsyncSubmit)="onSubmit()" [subscription]="loader">
    <div mat-dialog-content class="ft-busy-container" fxFlex="grow" fxLayout="column">
        <div fxLayout="row" class="machine-id-container">
            <div translate="products.license.machine_uuid" class="label tag mat-elevation-z1" fxFlex="0 0 33%"></div>
            <span class="tag mat-elevation-z1" fxFlex="noshrink">{{machineUuid}}</span>
            <span fxFlex="grow"></span>
            <button mat-icon-button fxFlex="none" color="primary" (click)="copyMachineCode()">
                <mat-icon fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
            </button>
        </div>
        <hr class="ft-sep">
        <mat-form-field appearance="outline" class="with-error-label" *ngIf="modeForm">
            <mat-label>{{'products.license.field_title' | translate}}</mat-label>
            <textarea matInput [placeholder]="'products.license.field_title' | translate" rows="14"
                      [(ngModel)]="licenseContent" name="licenceKey" required #licenceContentField="ngModel"
                      [ftCustomValidation]="validateField()" [noWatch]="true" [watchedValue]="licenseContent">
            </textarea>
            <mat-error *ngFor="let item of licenceContentField.errors | keyvalue">
                <span *ngIf="item.key.startsWith('license_error')">{{'products.license.' + item.key | translate}}</span>
            </mat-error>
        </mat-form-field>
        <div class="ft-empty-content" style="margin: 0" *ngIf="!modeForm && licenseError.error">
            <mat-icon fontSet="mdi" fontIcon="mdi-alert-decagram"></mat-icon>
            <span>{{'products.license.license_error_' + licenseError.code | translate}}</span>
        </div>
        <div fxLayout="column" *ngIf="!modeForm && !licenseError.error">
            <div fxLayout="row" fxFlex="noshrink">
                <div translate="products.license.client_name" class="label tag mat-elevation-z1" fxFlex="0 0 33%"></div>
                <div class="tag mat-elevation-z1" fxFlex="none">{{licenseObject.clientName}}</div>
            </div>
            <div fxLayout="row" fxFlex="noshrink">
                <div translate="products.license.global_id" class="label tag mat-elevation-z1" fxFlex="0 0 33%"></div>
                <div class="tag mat-elevation-z1" fxFlex="none">{{licenseObject.globalID}}</div>
                <span fxFlex="grow"></span>
                <button mat-icon-button fxFlex="none" type="button" color="primary" (click)="copyGlobalID()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-content-copy"></mat-icon>
                </button>
            </div>
            <div fxLayout="row" fxFlex="noshrink">
                <div translate="products.license.limit_date" [class.expired-warn]="expired"
                     class="label tag mat-elevation-z1" fxFlex="0 0 33%"></div>
                <div class="tag mat-elevation-z1" [class.expired-warn]="expired" *ngIf="licenseObject.limitDate"
                     fxFlex="none">{{licenseObject.limitDate}}</div>
                <div class="tag mat-elevation-z1" [class.expired-warn]="expired" *ngIf="!licenseObject.limitDate"
                     fxFlex="none" translate="products.license.unlimited"></div>
            </div>
            <div fxLayout="row" fxFlex="noshrink">
                <div translate="products.license.obligatory_license_policy" class="label tag mat-elevation-z1"
                     fxFlex="0 0 33%"></div>
                <mat-icon class="obligatory-license-policy mat-primary" *ngIf="licenseObject.obligatoryLicensePolicy"
                          fxFlex="none" fontSet="mdi" fontIcon="mdi-check-circle"></mat-icon>
                <mat-icon class="obligatory-license-policy mat-warn" *ngIf="!licenseObject.obligatoryLicensePolicy"
                          fxFlex="none" fontSet="mdi" fontIcon="mdi-close-circle"></mat-icon>
            </div>
            <div fxLayout="row" fxFlex="noshrink" *ngIf="licenseObject.demoUse">
                <div translate="products.license.demo_use" class="label tag mat-elevation-z1"
                     fxFlex="0 0 33%"></div>
                <mat-icon class="obligatory-license-policy mat-primary"
                          fxFlex="none" fontSet="mdi" fontIcon="mdi-check-circle"></mat-icon>
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row">
        <button fxFlex="0 0 auto" mat-flat-button color="warn" type="button" class="has-icon" *ngIf="!modeForm"
                (click)="modeForm=true;">
            <mat-icon fontSet="mdi" fontIcon="mdi-data-matrix-edit"></mat-icon>
            <span translate="products.license.edit"></span>
        </button>
        <span fxFlex="1 0 auto"></span>
        <button fxFlex="0 0 auto" mat-flat-button mat-dialog-close color="primary" type="button" tabindex="-1"
                *ngIf="!modeForm && !lockExit">
            <span translate="shared.cancel"></span>
        </button>
        <button fxFlex="0 0 auto" mat-flat-button color="primary" type="submit" tabindex="-1"
                *ngIf="modeForm" [disabled]="licenseForm.invalid">
            <span translate="products.license.submit"></span>
        </button>
    </div>
</form>

