import {Injectable} from '@angular/core';
import {FtWsService, GeneralPurposeService} from '@ft/core';
import {Observable} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SharedServiceModel} from '../classes/service.model';


@Injectable()
export class SharedServicesService {
    private _baseUrl = '/api/products';

    // public products$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>([]);

    constructor(
        private _ws: FtWsService,
        private _http: HttpClient,
        private _generalPurpose: GeneralPurposeService
    ) {
    }


    public getServices(): Observable<any[]> {
        return this._generalPurpose.getByHttp(`${this._baseUrl}/docker-services/`)
            .pipe(
                // map(data => data.map(item => new ServiceModel(item)))
                filter(data => data.filter(item => item.shared)),
                map(data => data.map(item => new SharedServiceModel(item)))
            );
    }

    public handleService(service, action) {
        return this._generalPurpose.getByEvent(`packages.${action}_service`, {service})
            .pipe(
                mergeMap(() => this.getServices())
            );
    }
}
