<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <nav mat-tab-nav-bar backgroundColor="accent" fxLayout="column">
        <a mat-tab-link *ngFor="let manager of managers; trackBy: trackByFn"
           [routerLink]="manager.path"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
            {{manager.label | translate}}
        </a>

        <span fxFlex></span>
    </nav>
    <router-outlet></router-outlet>
</div>
