export const lang = {
    title: 'SSH',
    login: 'Se connecter',
    cancel: 'Annuler',
    console: 'CONSOLE {{i}}',
    host: 'Host',
    port: 'Port',
    username: 'Username',
    password: 'Password',
    error_connect: 'Impossible de se connecter avec les informations fournies',
    error_open: 'Nombre de console limité'
};
