import {Component, OnInit, ViewChild} from '@angular/core';


@Component({
    templateUrl: './phpMyAdmin.component.html',
    styleUrls: ['./phpMyAdmin.component.scss']
})
export class PhpMyAdminComponent implements OnInit {
    fullPage: boolean;
    @ViewChild('iframe', {static: true}) iframe: any;

    constructor() {
    }

    ngOnInit(): void {
        this.fullPage = false;
        this.reloadPage();
    }

    toggleFullPage() {
        this.fullPage = !this.fullPage;
    }

    reloadPage() {
        this.iframe.nativeElement.src = '/phpmyadmin/';
    }
}
