<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-security"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="products.certificate.dialog_title"></h2>
    <span fxFlex></span>
    <mat-slide-toggle [(ngModel)]="certificate.using_valid_encrypt">
        <span translate="products.certificate.lets_encrypt"></span>
    </mat-slide-toggle>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #certificateForm="ngForm" fxLayout="column" (ftAsyncSubmit)="submit()">
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div [ngBusy]="loader"></div>
        <mat-form-field fxFlex>
            <input matInput required [placeholder]="'products.certificate.common_name' | translate" name="common_name"
                   [(ngModel)]="certificate.common_name" autocomplete="off" required>
        </mat-form-field>

        <div class="hr-container">
            <hr class="ft-sep with-text">
            {{"products.certificate.dns_list" | translate}}
            <hr class="ft-sep with-text">
        </div>

        <div fxLayout="row wrap" fxLayoutGap="6px">
            <mat-form-field fxFlex="calc(50% - 6px)"
                            *ngFor="let dns of certificate.dns_list; let i = index; trackBy:trackByIndex;">
                <input matInput [placeholder]="'products.certificate.dns_item' | translate:{'index': i+1}"
                       [name]="'dns_list[' + i + ']'" [(ngModel)]="certificate.dns_list[i]"
                       [ftAutoFocus]="i > 0 && dns.length == 0" autocomplete="off" [required]="certificate.dns_list.length > 1">
                <button matSuffix mat-icon-button (click)="removeDns(i, $event)"
                        [disabled]="certificate.dns_list.length == 1" type="button">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="hr-container" *ngIf="!certificate.using_valid_encrypt">
            <hr class="ft-sep with-text">
            {{"products.certificate.ip_list" | translate}}
            <hr class="ft-sep with-text">
        </div>

        <div fxLayout="row wrap" class="gaped-fields" *ngIf="!certificate.using_valid_encrypt">
            <mat-form-field fxFlex="calc(50% - 6px)"
                            *ngFor="let ip of certificate.ip_list; let i = index; trackBy:trackByIndex;">
                <input matInput [placeholder]="'products.certificate.ip_item' | translate:{'index': i+1}"
                       [name]="'ip_list[' + i + ']'" [(ngModel)]="certificate.ip_list[i]" autocomplete="off"
                       [pattern]="hostPattern" required [ftAutoFocus]="i > 0 && ip.length == 0">
                <button matSuffix mat-icon-button (click)="removeIp(i, $event)"
                        [disabled]="certificate.ip_list.length == 1" type="button">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" type="button" (click)="certificate.ip_list.push('')"
                [disabled]="certificateForm.invalid" class="has-icon" *ngIf="!certificate.using_valid_encrypt">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="products.certificate.add_ip"></span>
        </button>
        <button mat-stroked-button color="primary" type="button" (click)="certificate.dns_list.push('')"
                [disabled]="certificateForm.invalid" class="has-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="products.certificate.add_dns"></span>
        </button>
        <span fxFlex></span>
        <button mat-raised-button color="primary" type="submit"
                [disabled]="certificateForm.invalid">
            <span translate="common.save"></span>
        </button>
        <button mat-raised-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>
