<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
        </div>
        <h2 translate="printing.printers_label"></h2>
        <span fxFlex></span>
        <button mat-button class="with-icon" (click)="handleProfile()">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="printing.add_printer_profile"></span>
        </button>
    </mat-toolbar>

    <div fxFlex fxLayout="column" class="ft-content">
        <ft-generic-table fxFlex
                          [remote]="false"
                          [source]="source$"
                          [selectable]="false"
                          [hasPagination]="false"
                          [searchFocus]="null"
                          [columns]="columns">
        </ft-generic-table>
    </div>
</div>
