export const SSH_CMD = [
    {
        label: 'Network',
        items: [
            {label: 'RestartService', cmd: 'sudo service networking reload\n'},
            {label: 'UpdateInterfaces', cmd: 'sudo nano /etc/network/interfaces\n'},
            {label: 'SameInterface', cmd: 'auto eno1:index \riface eno1:index inet static \raddress 192.168.1.0/24'},
            {label: 'NewInterface', cmd: 'allow-hotplug eno1  \riface eno1 inet static \raddress 192.168.1.0/24 \rgateway 192.168.1.1 \rdns-nameservers 8.8.8.8 \rdns-search unassigned'},
        ]
    },
    {
        label: 'Products',
        items: [
            {label: 'ProductsPath', cmd: 'cd /opt/ft\n'},
            {label: 'PratisoftManage', cmd: '/opt/ft/Env/ft-pratisoft/bin/python /opt/ft/ft-pratisoft/backend/manage.pyc'},
            {label: 'ManagerManage', cmd: '/opt/ft/Env/ft-manager/bin/python /opt/ft/ft-manager/backend/manage.pyc'},
            {label: 'PacsManage', cmd: '/opt/ft/Env/ft-pacs/bin/python /opt/ft/ft-pacs/backend/manage.pyc'},
            {label: 'BillingManage', cmd: '/opt/ft/Env/ft-billing/bin/python /opt/ft/ft-billing/backend/manage.pyc'},
            {label: 'StockManage', cmd: '/opt/ft/Env/ft-stock/bin/python /opt/ft/ft-stock/backend/manage.pyc'},
        ]
    },
    {
        label: 'Various',
        items: [
            {label: 'SystemCtl', cmd: 'cd /etc/systemd/system/\n'},
            {label: 'NginxConf.d', cmd: 'cd /etc/nginx/conf.d\n'},
            {label: 'NginxReload', cmd: 'sudo service nginx reload\n'},
            {label: 'NginxConfig', cmd: 'sudo service nginx configtest\n'},
        ]
    }
];
