import {Injectable} from '@angular/core';
import {FtWsService, GeneralPurposeService, MEDIUM_DIALOG, pushOrUpdate} from '@ft/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {assign, chain, clone, get, noop, orderBy} from 'lodash';
import {ProductModel} from '../classes/product.model';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {ExecutionMessage} from '../classes/execution-message.model';
import {ServerLicenseErrorInterface, ServerLicenseInterface} from '../classes/server-license.interface';
import {ServiceModel} from '../classes/service.model';
import {ServerLicenseComponent} from '../dialogs/server-license/server-license.component';
import {MatDialog} from '@angular/material/dialog';
import {PackageLicenseComponent} from '../dialogs/package-license/package-license.component';

const EMPTY_SSL_CONFIG = {
    common_name: '',
    dns_list: [''],
    ip_list: ['']
};

@Injectable()
export class ProductsService {
    private _baseUrl = '/api/products';
    public products$: BehaviorSubject<ProductModel[]> = new BehaviorSubject<ProductModel[]>([]);
    public currentProduct$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(null);
    public license$: BehaviorSubject<any> = new BehaviorSubject<ServerLicenseInterface | ServerLicenseErrorInterface>(null);

    constructor(
        private _ws: FtWsService,
        private _http: HttpClient,
        private _generalPurpose: GeneralPurposeService,
        private _dialog: MatDialog
    ) {
    }

    public get obligatoryLicensePolicy(): boolean {
        return get(this.license$.getValue(), 'obligatoryLicensePolicy', false);
    }

    public get demoUse(): boolean {
        return get(this.license$.getValue(), 'demoUse', false);
    }

    public canExecute(): Observable<boolean> {
        return this._generalPurpose.getByEvent('packages.can_install');
    }

    public executionNotifySubscription(): Observable<boolean> {
        return this._ws.observe('packages.execution_notify')
            .pipe(
                mergeMap(() => this.canExecute())
            );
    }

    public getLicense(): Observable<ProductModel[]> {
        return this._generalPurpose.getByHttp(`/api/lic/main/`)
            .pipe(
                // map(data => data.map(item => new ProductModel(item))),
                // map(data => orderBy(data, ['is_manager', 'name'], ['asc', 'asc'])),
                tap(license => this.license$.next(license))
            );
    }

    public getProducts(): Observable<ProductModel[]> {
        return this._generalPurpose.getByHttp(`${this._baseUrl}/product/`)
            .pipe(
                map(data => data.map((item: any) => new ProductModel(item))),
                map(data => orderBy(data, ['is_manager', 'name'], ['asc', 'asc'])),
                tap(products => this.products$.next(products))
            );
    }


    public getProductServices(product: ProductModel): Observable<ServiceModel[]> {
        return this._generalPurpose.getByHttp(`${this._baseUrl}/product/${product.id}/services/`)
            .pipe(
                map(data => data.map(item => new ServiceModel(item)))
            );
    }

    public updateProduct(product: ProductModel): Observable<ProductModel> {
        return this._generalPurpose.postHttp(`${this._baseUrl}/product/`, product)
            .pipe(
                map(data => new ProductModel(data)),
                tap(item => {
                    const products = pushOrUpdate(this.products$.getValue(), item);
                    this.products$.next(products);
                })
            );
    }

    public getProductPackages(packageId: number, type: string): Observable<any[]> {
        const url = `${this._baseUrl}/package/?product=${packageId}&type=${type}`;
        return this._generalPurpose.getByHttp(url);
    }

    public uploadPackage(packageId: number, type: string, packageFile: File): Observable<any> {
        const data: FormData = new FormData();
        data.append('package', packageFile, packageFile.name);

        const subject = new Subject();
        const config = new HttpRequest('POST', `${this._baseUrl}/package/?product=${packageId}&type=${type}`, data, {
            reportProgress: true
        });

        this._generalPurpose.openProgressDialog(subject);

        return this._http.request(config)
            .pipe(
                tap(event => event.type === HttpEventType.UploadProgress ? subject.next(event) : noop()),
                filter(event => event.type === HttpEventType.Response),
                tap(() => subject.complete()),
                catchError(err => {
                    subject.complete();
                    return throwError(err);
                })
            );
    }

    public attachFromFs(packageId: number, type: string) {
        // DEV ONLY
        const x = 'C:\\Users\\thour\\Documents\\py-projects\\ft_products_holder\\pratisoft\\update\\pratisoft-update-2.0.4-ne-pas-installer-a-personne.ftip';

        return this._generalPurpose.openPromptDialog(
            'products.package.choose_existing_file', 'products.package.file_path', 'packages.check_file_existence',
            null, x, null, null, null, 'products.package.file_not_existence'
        ).pipe(
            mergeMap(path => this._generalPurpose.getByEvent('packages.load_package_from_fs', {
                product: packageId,
                type,
                path
            }))
        );
    }

    public deletePackage(item: any) {
        return this._generalPurpose.openConfirmDialog('products.package.remove_package', item)
            .pipe(
                mergeMap(() => this._deletePackageItem(item))
            );
    }

    public validatePackageLicense(product: ProductModel, license: string): Observable<any> {
        return this._generalPurpose.getByEvent('packages.validate_package_license', {product: product.id, license});
    }

    public validateServerLicense(license: string) {
        return this._generalPurpose.getByEvent('packages.validate_server_license', {license});
    }

    public updateServerLicense(body: string) {
        return this._generalPurpose.postHttp('/api/lic/main/', {body});
    }

    public updatePackageLicense(product: ProductModel, body: string) {
        return this._generalPurpose.postHttp('/api/lic/package/', {product: product.id, body});
    }


    public startExecution(item: any): Observable<ExecutionMessage> {
        const event = `packages.${this.currentProduct$.getValue().is_manager ? 'manager' : 'product'}_execution_process`;
        return this._generalPurpose.getByEvent(event, {pk: item.id})
            .pipe(
                map(data => new ExecutionMessage(data)),
                tap(data => {
                    if (data.product) {
                        const products = pushOrUpdate(this.products$.getValue(), data.product);
                        this.products$.next(products);
                    }
                })
            );
    }

    public handleService(service: any, action: string) {
        return this._generalPurpose.getByEvent(`packages.${action}_service`, {service})
            .pipe(
                mergeMap(() => this.getProductServices(this.currentProduct$.getValue()))
            );
    }

    private _deletePackageItem(item: any) {
        return this._generalPurpose.deleteHttp(`${this._baseUrl}/package/`, item);
    }

    // ssl build
    public getLastCertificate() {
        return this._generalPurpose.getByEvent('packages.ssl_certificate_config')
            .pipe(
                map(config => config ? config : clone(EMPTY_SSL_CONFIG)),
                map(config => {
                    config.ip_list = this._getProductsItems(config.ip_list, 'serve_ip');
                    config.dns_list = this._getProductsItems(config.dns_list, 'serve_dns');
                    return config;
                })
            );
    }

    public rebuildCertificate(config) {
        return this._generalPurpose.getByEvent('packages.rebuild_ssl', config);
    }

    private _getProductsItems(configItems, key): any[] {
        return chain(this.products$.getValue())
            .map(key)
            .concat(configItems)
            .uniq()
            .compact()
            .thru(ips => ips.length === 0 ? [''] : ips)
            .value();
    }

    public showLicenseManager(lock: boolean, license: ServerLicenseInterface | ServerLicenseErrorInterface) {
        return this._dialog.open(ServerLicenseComponent,
            assign(MEDIUM_DIALOG, {
                closeOnNavigation: lock,
                disableClose: lock,
                data: {
                    lock,
                    license
                }
            }));
    }

    public showPackageLicenseDialog(product: ProductModel) {
        return this._dialog.open(PackageLicenseComponent,
            assign(MEDIUM_DIALOG, {
                data: {
                    product
                }
            }));
    }
}
