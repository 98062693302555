import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SshMessage} from '../models/ssh-message';
import {SSH_MESSAGE_TYPES, SSH_WORKER_CMD} from '../models/enums';
import {workerUrl} from '../workers/terminal.worker';

@Injectable()
export class SshService {
    private readonly worker: Worker = null;
    public messages: Subject<any> = new Subject<any>();

    constructor() {
        const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
        const url = `${protocol}://${location.hostname}:${location.port}/terminal/`;

        this.worker = new Worker(workerUrl);
        this.worker.addEventListener('message', ($event: MessageEvent) => {
            const {cmd, data} = $event.data as any;

            if (cmd === SSH_WORKER_CMD.MESSAGE) {
                this.messages.next(data);
            }
        });

        this.worker.postMessage({cmd: SSH_WORKER_CMD.CONNECT, url});
    }

    public writeMsg(msg) {
        this.worker.postMessage({cmd: SSH_WORKER_CMD.MESSAGE, msg});
    }

    public open() {
        const msgObj = new SshMessage(SSH_MESSAGE_TYPES.OPEN);
        this.writeMsg(msgObj.toJson());
    }

    public connect(data) {
        const msg = new SshMessage(SSH_MESSAGE_TYPES.CONNECT, data);
        this.writeMsg(msg.toJson());
    }

    public read(channel) {
        const msg = new SshMessage(SSH_MESSAGE_TYPES.READ, channel);
        this.writeMsg(msg.toJson());
    }

    // tslint:disable-next-line:variable-name
    public write(channel_id, msg) {
        const msgObj = new SshMessage(SSH_MESSAGE_TYPES.WRITE, {channel_id, msg});
        this.writeMsg(msgObj.toJson());
    }

    // tslint:disable-next-line:variable-name
    public resize(channel_id, width, height) {
        const msgObj = new SshMessage(SSH_MESSAGE_TYPES.RESIZE, {channel_id, width, height});
        this.writeMsg(msgObj.toJson());
    }

    public close(channel) {
        const msgObj = new SshMessage(SSH_MESSAGE_TYPES.CLOSE, channel);
        this.writeMsg(msgObj.toJson());
    }

    public disconnect() {
        const msgObj = new SshMessage(SSH_MESSAGE_TYPES.DISCONNECT);
        this.writeMsg(msgObj.toJson());
    }

    public closeWS() {
        this.worker.terminate();
    }


    // check webgl support
    public detectWebGLContext() {
        // Create canvas element. The canvas is not added to the
        // document itself, so it is never displayed in the
        // browser window.
        const canvas = document.createElement('canvas');
        // Get WebGLRenderingContext from canvas element.
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        // Report the result.
        return gl && gl instanceof WebGLRenderingContext;
    }
}
