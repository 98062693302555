import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {TableColumnDefinition} from '@ft/core';
import {SharedServicesService} from '../services/shared-services.service';
import {SharedServiceModel} from '../classes/service.model';
import {MatDialog} from '@angular/material/dialog';
import {ServiceLogDialog} from '../dialogs/service-log/service-log.dialog';

@Component({
    selector: 'ftm-shared-services-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    public source$: Observable<any[]> = of([]);
    public columns: TableColumnDefinition[] = [
        {label: 'products.service.exec_main_pid', key: 'execMainPID', type: 'number'},
        {label: 'products.service.id', key: 'id', type: 'text'},
        {
            label: 'products.service.group_user',
            key: 'groupUser',
            type: 'text',
            callBack: (item: SharedServiceModel) => `${item.group}:${item.user}`
        },
        {
            type: 'badge',
            key: 'unitFileState',
            label: 'products.service.unit_file_state',
            class: (item: SharedServiceModel) => item.unitFileState
        },
        {
            type: 'badge',
            key: 'loadState',
            label: 'products.service.load_state',
            class: (item: SharedServiceModel) => item.loadState
        },
        {
            type: 'badge',
            key: 'activeState',
            label: 'products.service.active_state',
            class: (item: SharedServiceModel) => item.activeState
        },
        {
            type: 'badge',
            key: 'subState',
            label: 'products.service.sub_state',
            class: (item: SharedServiceModel) => item.subState
        },
        {label: 'products.service.fragment_path', key: 'fragmentPath', type: 'text'},
        {
            label: '', key: 'actions', type: 'actions', actions: [
                {
                    icon: 'mdi-restart',
                    class: 'mat-primary',
                    tooltip: 'products.service.start_restart',
                    isDisabled: item => item.notSuccess || item.isDisabled,
                    method: item => this.handleService(item, 'restart')
                },
                {
                    class: 'mat-primary',
                    isDisabled: item => item.isServiceNotFound,
                    icon: item => item.isDisabled ? 'mdi-ethernet-cable' : 'mdi-ethernet-cable-off',
                    tooltip: item => item.isDisabled ? 'products.service.enable' : 'products.service.disable',
                    method: item => this.handleService(item, item.isDisabled ? 'enable' : 'disable')
                },
                {
                    icon: 'mdi-stop',
                    class: 'mat-warn',
                    tooltip: 'products.service.stop',
                    isDisabled: item => item.notRunning || item.isDisabled,
                    method: item => this.handleService(item, 'stop')
                },
                {
                    icon: 'mdi-math-log',
                    class: 'mat-primary',
                    tooltip: 'shared_services.show_log',
                    isDisabled: item => item.isDisabled,
                    method: item => this.handleLog(item)
                }
            ]
        }
    ];

    constructor(private _service: SharedServicesService, private _dialog: MatDialog) {
        this.source$ = this._service.getServices();
    }

    ngOnInit(): void {
        this._service.getServices().subscribe(data => console.log(data));
    }

    public handleService(item: SharedServiceModel, action: string) {
        this.source$ = this._service.handleService(item.id, action);
    }

    public handleLog(service: SharedServiceModel) {
        this._dialog.open(ServiceLogDialog, {
            panelClass: 'monaco-dialog',
            data: {service}
        });
    }

}
