import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductsService} from '../../services/products.service';
import {Observable, of, Subscription} from 'rxjs';
import {TableColumnDefinition} from '@ft/core';
import {ServiceModel} from '../../classes/service.model';
import {ProductModel} from '../../classes/product.model';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'ftm-services-status',
    templateUrl: './services-status.component.html',
    styleUrls: ['./services-status.component.scss']
})
export class ServicesStatusComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;

    public source$: Observable<any[]> = of([]);
    public columns: TableColumnDefinition[] = [
        {label: 'products.service.exec_main_pid', key: 'execMainPID', type: 'number'},
        {label: 'products.service.id', key: 'id', type: 'text'},
        {
            label: 'products.service.group_user',
            key: 'groupUser',
            type: 'text',
            callBack: (item: ServiceModel) => `${item.group}:${item.user}`
        },
        {
            key: 'unitFileState',
            type: 'badge',
            label: 'products.service.unit_file_state',
            class: (item: ServiceModel) => item.unitFileState
        },
        {
            type: 'badge',
            key: 'loadState',
            label: 'products.service.load_state',
            class: (item: ServiceModel) => item.loadState
        },
        {
            type: 'badge',
            key: 'activeState',
            label: 'products.service.active_state',
            class: (item: ServiceModel) => item.activeState
        },
        {
            type: 'badge',
            key: 'subState',
            label: 'products.service.sub_state',
            class: (item: ServiceModel) => item.subState
        },
        {label: 'products.service.fragment_path', key: 'fragmentPath', type: 'text'},
        {
            label: '', key: 'actions', type: 'actions', actions: [
                {
                    icon: 'mdi-restart',
                    class: 'mat-primary',
                    tooltip: 'products.service.start_restart',
                    isDisabled: item => item.notSuccess || item.isManager || item.isDisabled,
                    method: item => this.handleService(item, 'restart')
                },
                {
                    class: 'mat-primary',
                    isDisabled: item => item.isManager || item.isServiceNotFound,
                    icon: item => item.isDisabled ? 'mdi-ethernet-cable' : 'mdi-ethernet-cable-off',
                    tooltip: item => item.isDisabled ? 'products.service.enable' : 'products.service.disable',
                    method: item => this.handleService(item, item.isDisabled ? 'enable' : 'disable')
                },
                {
                    icon: 'mdi-stop',
                    class: 'mat-warn',
                    tooltip: 'products.service.stop',
                    isDisabled: item => item.notRunning || item.isManager || item.isDisabled,
                    method: item => this.handleService(item, 'stop')
                }
            ]
        }
    ];

    constructor(
        private _service: ProductsService
    ) {
    }

    public ngOnInit() {
        this._subscription = this._service.currentProduct$
            .pipe(filter(product => !!product))
            .subscribe(p => this._updateProductServices(p));
    }

    public ngOnDestroy() {
        if (this._subscription) this._subscription.unsubscribe();
    }

    public handleService(item: ServiceModel, action: string) {
        this.source$ = this._service.handleService(item.id, action);
    }

    private _updateProductServices(product: ProductModel): void {
        this.source$ = this._service.getProductServices(product);
    }
}
