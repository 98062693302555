import {get} from 'lodash';

export declare type LoadStateType =
    'active'
    | 'inactive'
    | 'activating'
    | 'deactivating'
    | 'failed'
    | 'not-found'
    | 'running'
    | 'dead';


export class BaseServiceModel {
    public id: string;
    public user: string;
    public group: string;

    public execMainPID: number;
    public fragmentPath: string;
    public unitFileState: string;

    public loadState: LoadStateType;
    public activeState: LoadStateType;
    public subState: LoadStateType;

    constructor(data) {
        this.id = get(data, 'id');
        this.user = get(data, 'user');
        this.group = get(data, 'group');
        this.execMainPID = get(data, 'exec_main_pid');
        this.fragmentPath = get(data, 'fragment_path');
        this.unitFileState = get(data, 'unit_file_state');

        this.loadState = get(data, 'load_state');
        this.activeState = get(data, 'active_state');
        this.subState = get(data, 'sub_state');
    }

    get notSuccess(): boolean {
        return 'dead' === this.loadState;
    }

    get notRunning(): boolean {
        return 'dead' === this.subState || this.loadState === 'not-found';
    }

    get isDisabled(): boolean {
        return this.unitFileState === 'disabled';
    }

    get isServiceNotFound(): boolean {
        return this.loadState === 'not-found';
    }
}
