<form #form="ngForm" (submit)="save()">
    <div class="container ft-monaco-container">
        <div class="info" *ngIf="file.name">
            <div class="item">
                <span class="left">{{'file_manager.size' | translate}}:</span>
                <span class="right">{{file.size | ftFileSize}}</span>
            </div>
            <div class="item">
                <span class="left">{{'file_manager.created' | translate}}:</span>
                <span class="right">{{file.created_at | ftDatetime}}</span>
            </div>
            <div class="item">
                <span class="left">{{'file_manager.updated' | translate}}:</span>
                <span class="right">{{file.last_changed | ftDatetime}}</span>
            </div>
            <div class="item">
                <span class="left">{{'file_manager.permission' | translate}}:</span>
                <span class="right">{{permission}}</span>
            </div>
        </div>

        <ft-monaco-editor fxFlex name="content" [(ngModel)]="fileContent" (save)="save()" *ngIf="file.permissions"
                          [disabled]="!file.permissions['w']" [language]="file.type">
        </ft-monaco-editor>

    </div>

    <div *ngIf="file.permissions" class="ft-actions">
        <button mat-raised-button type="submit" [disabled]="form.invalid || !file.permissions['w'] " color="primary"
                class="has-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-content-save"></mat-icon>
            <span>{{'file_manager.save' | translate}}</span>
        </button>
        <button mat-raised-button type="button" class="has-icon" color="warn" (click)="cancel()">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span>{{'file_manager.cancel' | translate}}</span>
        </button>
    </div>

</form>


