import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {assign} from 'lodash';
import {ProductsService} from '../../services/products.service';
import {ProductModel} from '../../classes/product.model';
import {SMALL_DIALOG, TableColumnDefinition} from '@ft/core';
import {first} from 'rxjs/operators';
import {ProductFormDialog} from '../product-form/product-form.dialog';
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'ftm-products-manage',
    templateUrl: './products-manage.dialog.html',
    styleUrls: [
        './products-manage.dialog.scss'
    ]
})
export class ProductsManageDialog implements OnInit {
    public source$: Observable<ProductModel[]>;
    public columns: TableColumnDefinition[] = [
        {label: 'products.setups.product_name', key: 'name', type: 'text'},
    ];

    constructor(
        private _dialog: MatDialog,
        private _service: ProductsService,
    ) {
        this.source$ = this._service.products$.asObservable()
            .pipe(
                first()
            );
    }

    ngOnInit(): void {
    }

    updateProductMeta(product?: ProductModel) {
        const dialogRef = this._dialog.open(ProductFormDialog, assign(SMALL_DIALOG, {
            data: {product: product ?? {}},
        }));
    }
}
