<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="products.handle_product" [translateParams]="product"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<form #productForm="ngForm" fxLayout="column" (ftAsyncSubmit)="submit()" [subscription]="loader">
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div [ngBusy]="loader"></div>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput required [placeholder]="'products.serve_ip' | translate" name="serve_ip"
                       [(ngModel)]="product.serve_ip" autocomplete="off"
                       [pattern]="hostPattern">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'products.serve_dns' | translate" name="serve_dns"
                       [(ngModel)]="product.serve_dns" autocomplete="off">
            </mat-form-field>
        </div>

        <ng-container *ngIf="product.has_backup">
            <div class="hr-container">
                <hr class="ft-sep with-text">
                {{"products.backup.label" | translate}}
                <hr class="ft-sep with-text">
            </div>

            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput [placeholder]="'products.backup.main_args' | translate" name="backups_main_args"
                           [(ngModel)]="product.backups_main_args" autocomplete="off" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="6px" *ngFor="let backup of product.backups; let i = index">
                <mat-form-field fxFlex="15">
                    <input matInput [placeholder]="'products.backup.backups' | translate:{index: i+1}"
                           [name]="'backups[' + i + '].backups'"
                           [(ngModel)]="backup.backups" autocomplete="off" [ftNumber]="'float'" [fix]="0" [min]="1"
                           required>
                </mat-form-field>
                <mat-form-field fxFlex="25">
                    <input matInput [placeholder]="'products.backup.cron' | translate:{index: i+1}"
                           [name]="'backups[' + i + '].cron'"
                           [(ngModel)]="backup.cron" autocomplete="off" required>
                </mat-form-field>
                <mat-form-field fxFlex="calc(60% - 12px)">
                    <input matInput [placeholder]="'products.backup.destination' | translate:{index: i+1}"
                           [name]="'backups[' + i + '].destination'"
                           [(ngModel)]="backup.destination" autocomplete="off" required>

                    <button matSuffix mat-icon-button (click)="removeBackup(i, $event)" [disabled]="product.backups.length == 1">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </ng-container>

        <div class="hr-container">
            <hr class="ft-sep with-text">
            {{"products.installed_versions" | translate}}
            <hr class="ft-sep with-text">
        </div>

        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex *ngFor="let type of product.package_types">
                <input matInput [placeholder]="type" [name]="'installed_versions.' + type"
                       [(ngModel)]="product.installed_versions[type]" autocomplete="off"
                       [pattern]="semverPattern">
            </mat-form-field>
        </div>

        <div class="hr-container">
            <hr class="ft-sep with-text">
            {{"products.previous_versions" | translate}}
            <hr class="ft-sep with-text">
        </div>

        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex *ngFor="let type of product.package_types">
                <input matInput [placeholder]="type" [name]="'previous_versions.' + type"
                       [(ngModel)]="product.previous_versions[type]" autocomplete="off"
                       [pattern]="semverPattern">
            </mat-form-field>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" type="button" (click)="addBackup()"
                [disabled]="productForm.invalid" class="has-icon" *ngIf="product.has_backup">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="products.backup.add_backup"></span>
        </button>
        <span fxFlex></span>
        <button mat-raised-button color="primary" type="submit"
                [disabled]="productForm.invalid">
            <span translate="common.save"></span>
        </button>
        <button mat-raised-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>