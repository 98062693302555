<div class="ft-parent-layout" fxFlex fxLayout="column">

    <div fxLayoutAlign="center center" *ngIf="!connection && !isFirstConnect" class="container" fxFlex fxLayout="row">
        <button mat-raised-button (click)="openDialog()" color="primary">
            <span translate="ssh.login"></span>
        </button>
    </div>

    <mat-tab-group fxFlex animationDuration="0ms" [(selectedIndex)]="index" *ngIf="connection" headerPosition="below"
                   class="tab-group" (selectedTabChange)="onLinkClick($event)">
        <mat-tab *ngFor="let t of terms; let i=index" [label]="'ssh.console' | translate:{'i':i+1}">
            <div class="terminal" [id]="t">
                <button mat-mini-fab class="fab fab-plus" color="primary" (ftAsyncClick)="openTerminal()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
                <button *ngIf="terms.length > 1" mat-mini-fab class="fab fab-close" color="warn"
                        (click)="closeTerminal(t)">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
                <button mat-mini-fab class="fab fab-cmd" color="primary" [matMenuTriggerFor]="menu"
                        [matMenuTriggerData]="{channel:t}">
                    <mat-icon fontSet="mdi" fontIcon="mdi-console"></mat-icon>
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<mat-menu #menu="matMenu" class="ft-small-menu">
    <ng-template matMenuContent let-channel="channel">
        <button *ngFor="let groups of sshCmd" mat-menu-item [matMenuTriggerFor]="subMenu"
                [matMenuTriggerData]="{channel:channel, items: groups.items}">
            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-left"></mat-icon>
            <span>{{groups.label}}</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu" class="ft-small-menu">
    <ng-template matMenuContent let-channel="channel" let-items="items">
        <button *ngFor="let item of items" mat-menu-item (click)="copyCmd(channel, item.cmd)">
            <mat-icon fontSet="mdi" fontIcon="mdi-console-line"></mat-icon>
            <span>{{item.label}}</span>
        </button>
    </ng-template>
</mat-menu>

