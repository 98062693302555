import {Component, Inject, OnInit} from '@angular/core';
import {AppConfigsService} from '@ft/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../services/products.service';
import {get} from 'lodash';
import {ServerLicenseErrorInterface, ServerLicenseInterface} from '../../classes/server-license.interface';
import * as moment from 'moment';

@Component({
    selector: 'ftm-server-license',
    templateUrl: './server-license.component.html',
    styleUrls: ['./server-license.component.scss']
})
export class ServerLicenseComponent implements OnInit {
    machineUuid: string;
    lockExit: boolean;
    modeForm: boolean;
    expired: boolean;
    licenseObject: ServerLicenseInterface;
    licenseError: ServerLicenseErrorInterface;
    licenseContent = '';

    public loader: Subscription;


    constructor(
        private _appConfigs: AppConfigsService,
        private _service: ProductsService,
        private _translate: TranslateService,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) data: { lock: boolean; license: ServerLicenseInterface | ServerLicenseErrorInterface }
    ) {
        this.machineUuid = this._appConfigs.machineUuid;
        const licenseObject = get(data, 'license');

        if ('error' in licenseObject) {
            this.licenseObject = this._service.license$.getValue();
            this.licenseError = licenseObject;
            this.lockExit = true;
            this.modeForm = this.licenseError.no_license;
        } else {
            this.licenseObject = licenseObject;
            this.modeForm = !licenseObject;
            this.lockExit = data.lock;
            this.licenseError = {
                error: false
            };
        }
    }

    ngOnInit(): void {
        if (this.licenseObject) {
            const limitDate = moment(this.licenseObject.limitDate, 'YYYY-MM-DD');
            this.expired = limitDate.isBefore(moment());
        }
    }

    public copyMachineCode() {
        navigator.clipboard.writeText(this._appConfigs.machineUuid)
            .then(() => {
                const copyMsg = this._translate.instant('shared.machine_code_copy_success');
                this._snackBar.open(copyMsg, null, {duration: 1000});
            });
    }

    public copyGlobalID() {
        navigator.clipboard.writeText(this.licenseObject.globalID)
            .then(() => {
                const copyMsg = this._translate.instant('shared.machine_code_copy_success');
                this._snackBar.open(copyMsg, null, {duration: 1000});
            });
    }

    public onSubmit() {
        this.loader = this._service.updateServerLicense(this.licenseContent)
            .subscribe(() => {
                this._snackBar.open(this._translate.instant('products.license.installed'), null, {duration: 1000});
                setTimeout(() => window.location.reload(), 500);
            });
    }

    public validateField() {
        return this._service.validateServerLicense(this.licenseContent);
    }
}
