<div class="item-container" matRipple [matRippleDisabled]="disabledRead"
     [ngClass]="{'unreadable-item': disabledRead, 'draggable-item-container':!disabled}">
    <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-trigger"
            (click)="$event.stopPropagation()" *ngIf="!file.is_parent && !disabled">
        <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
    </button>

    <img [src]="file.icon" alt="" [ngClass]="{'disabled-file': disabledRead}">

    <div class="text" *ngIf="!file.is_parent" (click)="copyPath($event)">
        <p [matTooltip]="file.path">{{file.name}}</p>
    </div>
</div>

<mat-menu #menu="matMenu" xPosition="before" class="ft-small-menu">
    <button mat-menu-item (click)="download()" [disabled]="disabled">
        <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
        <span>{{'file_manager.download' | translate}}</span>
    </button>
    <button mat-menu-item (click)="renameModal()" [disabled]="disabled">
        <mat-icon fontSet="mdi" fontIcon="mdi-folder-edit"></mat-icon>
        <span>{{'file_manager.rename' | translate}}</span>
    </button>
    <button mat-menu-item (click)="moveModal()" [disabled]="disabled">
        <mat-icon fontSet="mdi" fontIcon="mdi-folder-move"></mat-icon>
        <span>{{'file_manager.move' | translate}}</span>
    </button>
    <button mat-menu-item (click)="delete()" [disabled]="disabled">
        <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
        <span>{{'file_manager.delete' | translate}}</span>
    </button>
</mat-menu>
