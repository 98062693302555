// @todo old license
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {get, findIndex} from 'lodash';
import {HEIGHT_ANIMATION, pushOrUpdate} from '@ft/core';
import {ProductsService} from '../../services/products.service';
import {ExecutionMessage} from '../../classes/execution-message.model';
import {Subscription} from 'rxjs';


@Component({
    selector: 'ftm-package-execution',
    templateUrl: './package-execution.dialog.html',
    animations: [
        HEIGHT_ANIMATION
    ],
    styleUrls: [
        './package-execution.dialog.scss'
    ]
})
export class PackageExecutionDialog implements OnInit {
    public readonly package: any;
    public showAdvMode = false;
    public updateOnly = false;
    public loader: Subscription = null;
    public message: ExecutionMessage = new ExecutionMessage({});
    public messages: ExecutionMessage[] = [];


    constructor(
        private _service: ProductsService,
        private _dialogRef: MatDialogRef<PackageExecutionDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.package = get(data, 'package', {});
        this.updateOnly = get(data, 'updateOnly', false);
    }

    ngOnInit() {
        if (!this.updateOnly) this.startInstallation();
    }

    public startInstallation() {
        this._service.startExecution(this.package)
            .subscribe(data => {
                const messageIndex = findIndex(this.messages, ['label', data.label]);
                if (messageIndex > -1 && data.hasError) {
                    this.messages[messageIndex].error = data.error;
                } else if (messageIndex > -1 && data.hasOutput) {
                    if (!!this.messages[messageIndex].output) {
                        this.messages[messageIndex].output += '\n';
                    } else {
                        this.messages[messageIndex].output = '';
                    }
                    this.messages[messageIndex].output += data.output;
                } else {
                    this.messages.push(data);
                }

                if (!this.showAdvMode && data.hasError) this.toggleAdvMode();

                this.message = data;
            });
    }

    public get progressMode() {
        if (!this.message) return 'buffer';
        else if (this.message.progress === -1) return 'indeterminate';
        else return 'determinate';
    }

    public toggleAdvMode() {
        this.showAdvMode = !this.showAdvMode;
    }
}
